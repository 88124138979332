<template>
    <div style="display: flex;justify-content:space-between;">
        <div>{{ projectId }}</div>
        <div>{{ projectId }}</div>

    </div>
</template>

<script>
export default {
    props: ["projectId"],
    methods: {
        loadPage() {
        }
    },
    mounted() {
        let that = this;
        that.loadPage();
    }

};
</script>

<style scoped>

</style>